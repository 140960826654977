@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  .headding {
    font-size: calc(1.4rem + 1.5vw);
    line-height: calc(1.9rem + 1.5vw);
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: $text-5xl;
    }
  }

  .max_content_text {
    max-width: 400px;

    @include media(md) {
      max-width: 540px;
    }

    @include media(lg) {
      max-width: 600px;
    }

    @include media(xl) {
      max-width: 660px;
    }

    @include media(2xl) {
      max-width: 814px;
    }
  }

  .content {
    display: flex;
    text-align: center;
    padding: 0 20px;

    @include media(md) {
      padding: 0 40px;
    }

    @include media(xl) {
      padding: 0 90px;
    }

    @include media(2xl) {
      padding: 0 104px;
    }

    @include media(3xl) {
      padding: 0 144px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto 0;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      text-align: center !important;
      align-items: center !important;
    }

    .body__text_heading {
      font-size: $text-lg !important;
      font-weight: 600;
      color: $gray-300;
      margin-top: 40px;
      margin-bottom: 12px;
      letter-spacing: 2%;

      @media screen and (min-width: 420px) {
        margin-top: 0;
      }
    }

    .body__title {
      @extend .headding;
      @extend .max_content_text;
      margin-bottom: 40px;
    }

    .body__contents_images {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 12px;

      @media screen and (min-width: 420px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media(sm) {
        gap: 24px;
        grid-template-columns: repeat(auto-fit, minmax(208px, 1fr));
      }
    }
  }

  a {
    color: $label-btn-link;
    white-space: pre-wrap;
    padding: 11px 16px !important;
    margin-top: 53px;
    padding: 0;
    font-size: $text-sm;
    line-height: 18px;
    font-weight: 600;
    order: 5;
  }

  .body__button_primary {
    display: block;
    text-transform: uppercase;
    text-align: center;
    border-radius: $border-radius-btn;
    min-height: 40px;
    font-weight: 500;
    background: $btn-primary;
    color: $label-btn-primary;
    order: 6;

    @include media(sm) {
      min-width: 234px;
    }
  }

  .body__figure {
    position: relative;
    width: 100%;
    padding-top: 48.28%;
    overflow: hidden;
    background-color: $gray-50;

    img {
      height: auto;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: $gray-300;

      @include media(sm) {
        font-size: 22px;
      }
    }
  }

  .body__figure_border_radius_under_sm {
    border-radius: $border-radius-md;
  }

  .body__square_picture {
    .body__title {
      margin-bottom: 16px;
    }

    p {
      @extend .max_content_text;
      margin-bottom: 56px;
      color: $gray-300;

      @include media(sm) {
        font-size: $text-xl;
        line-height: 26px;
      }
    }

    a {
      margin-top: 67px;
    }

    .body__figure {
      width: 100%;
      padding-top: 110%;
    }
  }
}
