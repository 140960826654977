@import '../../styles/variables';
@import '../../styles/mixin';

.side_by_side__section {
  padding: 40px 0;
  display: flex;
  flex-direction: column;

  @include media(lg) {
    padding: 0;
  }

  &_with_padding {
    @include media(lg) {
      padding: 64px 0;
    }
  }

  .heading_section {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    max-width: 1016px;

    h2 {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 16px;

      @include media(lg) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 16px;
      }
    }

    p {
      max-width: 660px;
    }
  }
}

.container {
  position: relative;
  //padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include media(lg) {
    display: grid;
    gap: 0px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    &__left {
      .body {
        order: 1 !important;
      }
      .body__figure {
        order: 0 !important;
      }
    }
  }

  .body {
    display: flex;
    width: 100%;
    order: 1;

    @include media(lg) {
      order: 0;
      padding-left: var(--content-padding-left);
      padding-right: var(--content-padding-right);
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 100%;

      &_with_background {
        height: 100%;
        justify-content: center;
        align-self: stretch;
        padding: 10%;
      }

      .body__text_heading {
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.02em;
        margin-bottom: 24px;

        @include media(lg) {
          margin-bottom: 32px;
          font-size: 16px;
        }
      }

      .body__heading_icon {
        overflow: hidden;
        margin-bottom: 24px;

        @include media(lg) {
          margin-bottom: 32px;
        }

        & > img {
          width: 62px;
          height: 62px;

          @include media(lg) {
            width: 80px;
            height: 80px;
          }
        }

        &__custom_width {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .body__title_container {
        display: flex;
        flex-direction: column;
        order: 1;
        margin-bottom: 24px;
        gap: 12px;

        @include media(lg) {
          margin-bottom: 32px;
          flex-direction: row;
          gap: 24px;
        }

        .body__title {
          order: var(--title-order);
          font-size: $text-lg + $text-xs;
          line-height: 40px;
          font-weight: 600;
          letter-spacing: -0.02px;

          @include media(lg) {
            font-size: 32px;
            line-height: 40px;
          }
          @include media(2xl) {
            font-size: 40px;
            line-height: 48px;
          }
          @include media(3xl) {
            font-size: 48px;
            line-height: 56px;
          }
        }

        .body__title_icon {
          order: var(--icon-order);
          display: contents;

          & > img {
            width: 62px;
            height: 62px;

            @include media(lg) {
              width: 80px;
              height: 80px;
            }
          }

          &__custom_width {
            width: 100%;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .body__title_icon_centered {
        margin-bottom: 24px;
        margin-top: -24px;
        order: 2;

        & > span,
        img {
          width: 62px;
          height: 62px;

          @include media(lg) {
            width: 80px;
            height: 80px;
          }
        }

        &__custom_width {
          width: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .description {
        order: 3;
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.02em;

        @include media(lg) {
          font-size: 16px;
        }

        p,
        img,
        ul,
        ol {
          margin-bottom: 6px;
        }

        h4,
        h5,
        h6 {
          font-size: $text-lg;
          margin-bottom: 4px;
        }

        h5 {
          font-size: $text-base;
        }
        h6 {
          font-size: $text-sm;
        }
      }
    }
  }

  .body__figure {
    position: relative;
    flex: 1;
    height: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: $gray-50;
    order: 0;
    padding-top: 74%;

    @include media(lg) {
      position: relative;
      width: 100%;
      padding-top: 83%;
      overflow: hidden;
      order: 1;
      align-self: center;
    }

    &__with_flat_image {
      padding-top: 47.98%;

      @include media(lg) {
        aspect-ratio: unset;
        padding-top: 31.22%;
      }
    }

    img {
      height: auto;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      color: $gray-300;
    }
  }
}

.carousel_image_variation_section {
  position: relative;
  height: 100%;
  display: flex;
}

.cta {
  line-height: 18px;
  font-weight: 600;
  order: 5;
  transition: all ease 0.3s;
}

.single_cta {
  margin-top: 32px;
  align-self: var(--cta-align);
  width: 293px;

  @include media(xl) {
    width: 366px;
  }

  &_flat {
    width: 190px;

    @include media(lg) {
      width: 236px;
    }
  }
}

.cta:hover {
  cursor: pointer;
  border: var(--cta-border-on-hover) !important;
  background: var(--cta-bg-on-hover) !important;
  color: var(--cta-color-on-hover) !important;
}

.body__button_primary {
  display: block;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $text-sm;
  text-align: center;
  border-radius: $border-radius-btn;
  height: 40px;
  max-width: 293px;
  font-weight: 500;
  flex-grow: 1;

  @include media(lg) {
    max-width: 366px;
    height: 42px;
  }
}

.cta_section {
  order: 6;
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 32px;
  justify-content: space-between;

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }

  @include media(lg) {
    justify-content: var(--cta-section-align);
    gap: 32px;
  }

  & > * {
    flex: 1;
    max-width: 293px;
    margin-top: 0px;

    @include media(lg) {
      max-width: 366px;
    }
  }
}

.cta_with_social_icons {
  order: 3;
}

.flat_image_variation_section {
  position: relative;
  padding: 24px 20px;

  .body__figure {
    align-self: center;
  }
}

.cta_on_image_variation_section {
  margin-top: 52px;
}

.image_gallery_variation_section {
  margin-top: 52px;
}
